<template>
  <NavBar class="navbarone" />
</template>
<script>
import NavBar from "./components/NavBar.vue";
export default {
  name: "HeaDer",
  components: {
    NavBar,
  },
};
</script>
<style scoped>
.navbarone {
  margin-bottom: 30px;
}
</style>
