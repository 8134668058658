import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
// eslint-disable-next-line
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import store from "@/store";
export default class Firebase {
  constructor() {
    //const fileName = "Articles_pictures";
    // TODO: Replace the following with your app's Firebase project configuration
    const firebaseConfig = {
      apiKey: "AIzaSyBbQwe79S-bVonduIg850V13KyE_GtDfU4",
      authDomain: "hjvalue-77d41.firebaseapp.com",
      projectId: "hjvalue-77d41",
      storageBucket: "hjvalue-77d41.appspot.com",
      messagingSenderId: "818040771847",
      appId: "1:818040771847:web:74cf2bc8b5519e692ba1a2",
      measurementId: "G-DM8ENH2YFN",
    };
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Initialize Cloud Storage and get a reference to the service
    this.storage = getStorage(app);
    //database
    this.db = getFirestore(app);
    // Initialize Firebase Authentication
    this.auth = getAuth(app);
  }
  //set the image in ArticleCard.vue
  setImage(fileName, imgid) {
    getDownloadURL(ref(this.storage, fileName))
      .then((url) => {
        const image = document.getElementById(imgid);
        image.setAttribute("src", url);
      })
      .catch((error) => {
        console.log("el error fue: ", error);
      });
  }
  //get URL image for NavBar.vue
  async getURLbackground(fileName) {
    const backURL = await getDownloadURL(ref(this.storage, fileName));
    return backURL;
  }
  // Get a list of documents from your database
  async getDocuments() {
    const Col = collection(this.db, "Article_Documents");
    const colSnapshot = await getDocs(Col);
    const colList = colSnapshot.docs.map((doc) => doc.data());
    return colList;
  }
  //from here authentication
  async createUser(email, password) {
    return await createUserWithEmailAndPassword(this.auth, email, password);
  }

  async signIn(email, password) {
    return await signInWithEmailAndPassword(this.auth, email, password);
  }
  state(to, from, next) {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        const activeUser = { email: user.email, uid: user.uid };
        store.dispatch("detectUser", activeUser);
        next();
      } else {
        // User is signed out
        store.dispatch("detectUser", user);
        next({ path: "/system" });
      }
    });
  }
  async logOut() {
    return await signOut(this.auth);
  }
}
