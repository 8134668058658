<template>
  <FooterMain class="footerMain" />
</template>
<script>
import FooterMain from "@/components/FooterMain.vue";
export default {
  name: "FooTer",
  components: {
    FooterMain,
  },
};
</script>
<style scoped>
.footerMain {
  margin-top: 90px;
}
</style>
