import { createStore } from "vuex";
import FireBase from "@/services/Firebase.js";

export default createStore({
  state: {
    Article_Documents: "",
    Navbar_Background: "",
    MainBody_Background: "",
    Footer_Background: "",
    topics_dir_control: [],
    topics_dir: [],
    //authentication
    user: null,
    credentials: null,
  },
  getters: {
    getArticleDocuments(state) {
      return state.Article_Documents;
    },
    getNavBackground(state) {
      return state.Navbar_Background;
    },
    getMainBodyBackground(state) {
      return state.MainBody_Background;
    },
    getFooterBackground(state) {
      return state.Footer_Background;
    },
    getTopicsDir(state) {
      return state.topics_dir;
    },
    //authentication
    existUser(state) {
      if (state.user == null) {
        return false;
      } else {
        return true;
      }
    },
    getCredentials(state) {
      return state.credentials;
    },
  },
  mutations: {
    setArticleDocuments(state, objDoc) {
      state.Article_Documents = objDoc;
    },
    setNavBar_BG(state, bg) {
      state.Navbar_Background = bg;
    },
    setMainBody_BG(state, bg) {
      state.MainBody_Background = bg;
    },
    setFooter_BG(state, bg) {
      state.Footer_Background = bg;
    },
    mutateTopicDir(state, objdir) {
      if (!state.topics_dir_control.includes(objdir.title)) {
        state.topics_dir_control.push(objdir.title);
        state.topics_dir.push(objdir);
      }
    },
    //authentication
    setUser(state, payLoad) {
      state.user = payLoad;
    },
    setCredentials(state, payLoad) {
      state.credentials = payLoad;
    },
  },
  actions: {
    firebaseSetImage(context, { directory, imgID }) {
      new FireBase().setImage(directory, imgID);
    },
    firebaseGetDocuments(context) {
      const objDoc = new FireBase().getDocuments();
      context.commit("setArticleDocuments", objDoc);
    },
    firebaseSetNavbarBG(context, { directory }) {
      const bg = new FireBase().getURLbackground(directory);
      context.commit("setNavBar_BG", bg);
    },
    firebaseSetMainBodyBG(context, { directory }) {
      const bg = new FireBase().getURLbackground(directory);
      context.commit("setMainBody_BG", bg);
    },
    firebaseSetFooterBG(context, { directory }) {
      const bg = new FireBase().getURLbackground(directory);
      context.commit("setFooter_BG", bg);
    },
    setTopicsDir(context, objdir) {
      context.commit("mutateTopicDir", objdir);
    },
    //authentication
    detectUser({ commit }, user) {
      commit("setUser", user);
    },
    detectCredentials({ commit }, credentials) {
      commit("setCredentials", credentials);
    },
  },
  modules: {},
});
