<template>
  <div class="fill"></div>
  <div class="bottom-nav"></div>
  <router-view />
  <div class="upper-foot"></div>
</template>
<script>
export default {
  name: "App",
  components: {},
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.fill {
  width: 90%;
  height: 190px;
  border-bottom: 2px solid #656362;
  margin: auto;
}
.bottom-nav {
  height: 20px;
}
.upper-foot {
  width: 90%;
  height: 10px;
  border-bottom: 2px solid #656362;
  margin: auto;
  padding-top: 60px;
}
@media screen and (min-width: 460px) {
  #app {
    background: #ecedf5;
  }
}
</style>
