import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Header from "./HeaDer.vue";
import Footer from "./FooTer.vue";

createApp(Header).use(router).use(store).mount("#header");
createApp(App).use(router).use(store).mount("#app");
createApp(Footer).use(router).use(store).mount("#footer");
