import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import FireBase from "@/services/Firebase.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/articles",
    name: "Articles",
    component: () =>
      import(/* webpackChunkName: "articles" */ "../views/ArticlesView.vue"),
    children: [
      {
        path: "all",
        component: () =>
          import(
            /* webpackChunkName: "articles" */ "../components/ArticlesLanding.vue"
          ),
      },
      {
        path: "github",
        component: () =>
          import(
            /* webpackChunkName: "articles/github" */ "../components/GithubPage.vue"
          ),
        children: [
          {
            path: ":githubname",
            component: () =>
              import(
                /* webpackChunkName: "articles/github" */ "@/components/GithubPageArticles.vue"
              ),
            props: true,
          },
        ],
      },
      {
        path: "linkedin",
        component: () =>
          import(
            /* webpackChunkName: "articles/github" */ "../components/GithubPage.vue"
          ),
        children: [
          {
            path: ":linkedinname",
            component: () =>
              import(
                /* webpackChunkName: "articles/github" */ "@/components/LinkedinPageArticles.vue"
              ),
            props: true,
          },
        ],
      },
      {
        path: "app",
        component: () =>
          import(
            /* webpackChunkName: "articles/github" */ "../components/GithubPage.vue"
          ),
        children: [
          {
            path: ":appname",
            component: () =>
              import(
                /* webpackChunkName: "articles/github" */ "@/components/AppPageArticles.vue"
              ),
            props: true,
          },
        ],
      },
      {
        path: ":name",
        component: () =>
          import(
            /* webpackChunkName: "articles" */ "../components/ArticleContents.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/system",
    name: "Entrance",
    component: () =>
      import(/* webpackChunkName: "system" */ "../views/EntranceView.vue"),
  },
  {
    path: "/system-in",
    name: "Systemin",
    component: () =>
      import(/* webpackChunkName: "system" */ "../views/SystemView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    children: [
      {
        path: "engineer",
        component: () =>
          import(
            /* webpackChunkName: "about/engineer" */ "../components/EnginerResume.vue"
          ),
      },
      {
        path: "developer",
        component: () =>
          import(
            /* webpackChunkName: "about/developer" */ "../components/DeveloperResume.vue"
          ),
      },
      {
        path: "curriculum",
        component: () =>
          import(
            /* webpackChunkName: "about/curriculum" */ "../components/PensumResume.vue"
          ),
      },
      {
        path: "soitave",
        component: () =>
          import(
            /* webpackChunkName: "about/curriculum" */ "../components/PensumSoitave.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const firebase = new FireBase();
    //console.log(firebase.auth.currentUser);
    firebase.state(to, from, next);
  } else {
    next();
  }
});
export default router;
