<template>
  <div
    id="carrusel-steve"
    class="carousel carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-indicators">
      <button
        v-for="picture in pictures"
        :key="picture.item"
        type="button"
        data-bs-target="#carrusel-steve"
        :data-bs-slide-to="picture.item"
        :class="Number(picture.item) == 0 ? 'active' : ''"
        aria-current="true"
        :aria-label="slides(picture.item)"
      ></button>
    </div>
    <div class="carousel-inner">
      <div
        v-for="picture in pictures"
        :key="picture.item"
        :class="
          Number(picture.item) == 0 ? 'carousel-item active' : 'carousel-item'
        "
        :data-bs-interval="Number(picture.item) == 0 ? '10000' : '2000'"
      >
        <img
          :src="getImgUrl(`${picture.image}`)"
          class="d-block w-100"
          v-bind:alt="picture.image"
        />
        <div class="carousel-caption d-none d-md-block">
          <h5>{{ picture.title }}</h5>
          <p>{{ picture.description }}</p>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carrusel-steve"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carrusel-steve"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>
<script>
export default {
  name: "CarouselBar",
  data() {
    return {
      pictures: [
        {
          item: 0,
          image: "pic_01.jpg",
          title: "Steel - concrete",
          description: "STRUCTURES",
        },
        {
          item: 1,
          image: "pic_02.jpg",
          title: "Pre Design - Construction",
          description: "PROJECT MANAGEMENT",
        },
        {
          item: 2,
          image: "pic_03.jpg",
          title: "Quality-Price Balance",
          description: "VALUE ENGINEERING",
        },
        {
          item: 3,
          image: "pic_04.jpg",
          title: "Useful in Construction Industry",
          description: "TECHNOLOGY",
        },
        {
          item: 4,
          image: "pic_05.jpg",
          title: "You're Welcome",
          description: "CHALLENGES",
        },
        {
          item: 5,
          image: "pic_06.jpg",
          title: "A Great Value",
          description: "COMMITMENT",
        },
      ],
    };
  },
  methods: {
    slides(item) {
      const slide = Number(item) + 1;
      return String(`Slide ${slide}`);
    },
    getImgUrl(pic) {
      return require("../assets/home_pictures/" + pic);
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  font-size: 40px;
}
p {
  font-size: 30px;
}
.carousel-caption {
  margin-bottom: 60px;
  color: #ffd16c;
}
#carrusel-steve {
  width: 100%;
  max-width: 630px;
  height: auto;
}
</style>
