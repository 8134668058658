<template>
  <footer class="footer-20192">
    <div class="site-section">
      <div class="container">
        <div
          class="cta d-block d-md-flex align-items-center px-5"
          :style="banner"
        >
          <div>
            <h2 class="mb-0">Ready to Increase Your Value!..</h2>
            <h3>So let's get started!</h3>
          </div>
          <div class="ml-auto">
            <a href="#" class="btn"
              ><i class="bi bi-hand-thumbs-up-fill"></i
            ></a>
          </div>
        </div>
        <div class="row rowfoot-one">
          <div class="col-sm-4">
            <router-link class="navbar-brand" to="/">
              <img src="@/assets/HJValueLogo.svg" alt="" />
            </router-link>
          </div>
          <div class="col-sm-8 social_area">
            <div>
              hjvalue is a personal web page developed by Héctor José Vurchio
              Hurtado whose main interests goes on the combination of civil
              engineering practices with technology usage.
            </div>
            <div>
              <a href="https://www.linkedin.com/in/hector-vurchio/"
                ><i class="bi bi-linkedin"></i
              ></a>
              <a href="https://www.youtube.com/channel/UCQnAXfOxQoePhjFLZ9gEcew"
                ><i class="bi bi-youtube"></i
              ></a>
            </div>
            <div>www.hjvalue.com 2022</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line no-unused-vars
import bootstrap from "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
export default {
  name: "FooterMain",
  data() {
    return {
      banner: { backgroundImage: "" },
    };
  },
  created() {
    this.$store.dispatch("firebaseSetFooterBG", {
      //directory in firebase
      directory: "Footer_BG/light-brick.jpg",
    });
    this.$store.getters.getFooterBackground.then((url) => {
      this.banner.backgroundImage = `url('${url}')`;
    });
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.footer-20192 {
  position: relative;
  background-color: #040505;
}
.footer-20192 .cta {
  /*width: 90%;*/
  margin: auto;
  -webkit-box-shadow: -20px -20px 0 0 rgb(52 58 64 / 20%);
  box-shadow: -20px -20px 0 0 rgb(232 220 188);
  padding: 20px;
  top: -40px;
  position: relative;
}
.footer-20192 .cta h2,
.footer-20192 .cta h3 {
  line-height: 1.5;
}
.footer-20192 .cta h2 {
  color: #441904;
  text-shadow: 2px 2px 2px #7c300b;
}

.footer-20192 .cta h3 {
  font-size: 20px;
  color: #fff;
  text-shadow: 2px 2px 2px #7c300b;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
img {
  max-width: 100%;
  max-height: 100%;
}
.btn {
  background: black;
  border: 3px solid #ffd16c;
  color: #ffd16c;
  font-size: 30px;
  border-radius: 50%;
}

.social_area > div:nth-child(1) {
  color: #977255;
  font-size: 18px;
  font-family: "Roboto";
  font-style: italic;
  text-align: left;
}
.social_area > div:nth-child(2) {
  display: flex;
  justify-content: space-around;
  width: 70%;
  margin: auto;
  font-size: 26px;
}
.social_area > div:nth-child(2) > a:nth-child(1) {
  color: #0077b5;
}
.social_area > div:nth-child(2) > a:nth-child(2) {
  color: #ff0000;
}
.social_area > div:nth-child(3) {
  position: relative;
  top: 0px;
  text-align: right;
  color: #ac9556;
  font-weight: bold;
}
</style>
