<template>
  <div class="container-sm" :style="bgpaper">
    <div class="row justify-content-around">
      <div class="col concept">
        <p>
          Value is a complex concept which involves several fields or topics,
          say value is similar to say beauty, behavior, to express develop and
          some other words that define combination of qualities and patterns
          established by persons, groups, authorities, places, conditions and
          many others.
        </p>
        <p>
          Gennerally speaking, value in a society refers to determined conducts
          and beliefs which take place in particular cultures and social groups.
          Approaching top-down branches until workplace, a set of words such as
          "valuable skills" is often used in order to gauge thevalue of an
          employee.
        </p>
      </div>
      <div class="col d-flex justify-content-center align-items-center">
        <CarouselBar class="col-7 carousel" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p>
          When a currency of a country is able to be interchangeable for,
          depends on the value of it has and interestingly enough roam around in
          the trust of inhabitants and not in gold or oil reserves of the area
          in which it moves. In addition, the concept of value is enormous,
          therefore will be atomically broke down walking through the
          construction scenario by which this site is intended for.
        </p>
        <p>
          In places where the economy is growing so fast and with it demand for
          accomodation which in turn creates a state of unbalance in the housing
          market leading to increase both renting and selling prices of the
          area. Such an undersupply means opportunity for people who have the
          propper asset at the right moment getting more money for the situation
          than for the value itself. Indeed, value is the main criteria that a
          developer must take into account prior to make a decision to acquire a
          piece of land, being able to deliver all the necessary steps from
          design to closeout the construction.
        </p>
      </div>
    </div>
  </div>
  <InfoArea class="infoArea" />
</template>

<script>
import CarouselBar from "@/components/CarouselBar.vue";
import InfoArea from "@/components/InfoArea.vue";
export default {
  name: "HomeView",
  components: {
    CarouselBar,
    InfoArea,
  },
  data() {
    return {
      bgpaper: {
        backgroundImage: "",
        opacity: "0.7",
        "box-shadow": "5px 10px 8px 10px #cfc8bb",
      },
    };
  },
  created() {
    this.$store.dispatch("firebaseSetMainBodyBG", {
      //directory in firebase
      directory: "MainBody_BG/texturedwall.jpg",
    });
    this.$store.getters.getMainBodyBackground.then((url) => {
      this.bgpaper.backgroundImage = `url('${url}')`;
    });
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
.row {
  margin: auto;
}
.concept {
  min-width: 300px;
  padding-right: 50px;
}
.carousel {
  min-width: 300px;
}
p {
  text-align: justify;
  font-family: "Roboto Condensed", sans-serif;
  color: black;
  font-size: 20px;
  text-indent: 50px;
}
.infoArea {
  margin-top: 70px;
}
/*:-moz-first-node@media (max-width: 770px) {
  .carousel {
    min-width: 500px;
  }
}
@media (max-width: 570px) {
  .carousel {
    min-width: 300px;
  }
}*/
@media (max-width: 470px) {
  .info-area {
    width: 90%;
  }
  p {
    font-size: 18px;
  }
  .concept {
    min-width: 300px;
    padding-right: 20px;
  }
}
</style>
