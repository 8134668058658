<template>
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-light bg-white"
    id="mainNav"
    :style="banner"
  >
    <div class="container-fluid d-flex align-items-end">
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/HJValueLogo.svg" alt="#" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link
              class="nav-link fs-4 fw-light"
              aria-current="true"
              to="/about/engineer"
              >ABOUT</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link fs-4 fw-light" to="/articles/all"
              >ARTICLES</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link fs-4 fw-light" to="/system"
              >SYSTEM</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line no-unused-vars
import bootstrap from "bootstrap";
export default {
  name: "NavBar",
  data() {
    return {
      banner: { backgroundImage: "" },
    };
  },
  created() {
    this.$store.dispatch("firebaseSetNavbarBG", {
      //directory in firebase
      directory: "Nav_Bar/NavCream.jpg",
    });
    this.$store.getters.getNavBackground.then((url) => {
      this.banner.backgroundImage = `url('${url}')`;
    });
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@800&display=swap");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.container-fluid {
  font-family: "Barlow Condensed", sans-serif;
}
.router-link-active.router-link-exact-active.nav-link {
  color: #a78542;
}
.nav-item {
  width: 100px;
}
ul {
  border-top: 1px solid rgb(89 87 86);
  border-bottom: 1px solid rgb(99 97 97);
}
.navbar {
  width: 100%;
  max-width: 1600px;
  height: auto;
}
img {
  width: 320px;
  height: 140px;
}
@media (max-width: 470px) {
  img {
    width: 224px;
    height: 98px;
  }
}
</style>
