<template>
  <div class="container">
    <div class="row">
      <div class="col-sm steve-data">
        <div class="steve-card">
          <img
            id="stevepic"
            src="@/assets/info_area_pic/hector.jpg"
            alt=""
            width="140"
            height="140"
          />
          <div class="main-msj">
            <q class="ceo_quotes">
              <span class="firstLetter">L</span
              ><span
                >iving in the Informarion Age in which the way of what we are
                performing our work is violent changing, technology plays a
                fundamental role in the organizational value not just by
                allowing rapid responses, manage simulations or execution
                optimization but to percieve the surrounding external behaviors
                that affects decisions making in poject investments. Every new
                project brings challenges which in fact give reasons for deliver
                it until end and beyond.</span
              >
            </q>
          </div>
          <div>
            <p class="name">Hector J. Vurchio H.</p>
            <p class="job-title"><span>www.hjvalue.com</span></p>
          </div>
        </div>
      </div>
      <!--<div class="col-sm-1"></div>-->
      <div class="col-sm right-info">
        <div class="row special-head"><h2>Topics of Interest:</h2></div>
        <div class="row">
          <div class="col-6 col-sm-6 p-3 border bg-light service-card">
            <img
              src="@/assets/info_area_pic/estructura.jpg"
              alt=""
              style="width: 100%"
            />
            <div class="innercard">
              <h3>Structures</h3>
              <ul>
                <li>Steel</li>
                <li>Concrete</li>
                <li>Design & Construction</li>
              </ul>
            </div>
          </div>
          <div class="col-6 col-sm-6 p-3 border bg-light service-card">
            <img
              src="@/assets/info_area_pic/foundation.jpg"
              alt="Mountains"
              style="width: 100%"
            />
            <div class="innercard">
              <h3>Project Management</h3>
              <ul>
                <li>Pre Design</li>
                <li>Schematic Design</li>
                <li>Design Development</li>
                <li>Construction Documents</li>
                <li>Construction</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 p-3 border bg-light specialty-three">
            <img
              src="@/assets/info_area_pic/valueEngineer.jpg"
              alt=""
              width="50%"
              height="160px"
            />
            <div class="innercard">
              <h3>Value Engineering</h3>
              <ul>
                <li>Market Approach</li>
                <li>Market Capitalization</li>
                <li>Cost Approach</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 p-3 border bg-light specialty-three">
            <img
              src="@/assets/info_area_pic/technology.jpg"
              alt=""
              width="50%"
              height="160px"
            />
            <div class="innercard">
              <h3>Technology</h3>
              <ul>
                <li>CAD Systems</li>
                <li>Front End Technologies</li>
                <li>Back End Technologies</li>
                <li>Infrastructure</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line no-unused-vars
import bootstrap from "bootstrap";
export default {
  name: "InfoArea",
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital@1&family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.steve-data {
  display: flex;
  align-items: center;
  color: rgb(162 129 97);
  min-width: 300px;
}
#stevepic {
  float: left;
  margin: 5px;
  border-radius: 50%;
}
.main-msj {
  text-align: justify;
}
q {
  display: inline;
  text-justify: auto;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
}

q:before {
  content: open-quote;
  position: relative;
  top: -18px;
  float: left;
  font-size: 80px;
}

q:after {
  content: close-quote;
  position: relative;
  top: -30px;
  font-size: 80px;
  float: right;
}
q .firstLetter {
  float: left;
  font-family: Georgia;
  font-size: 75px;
  line-height: 60px;
  padding-top: 8px;
  padding-right: 8px;
  padding-left: 3px;
}
.name {
  font-family: "Sacramento", cursive;
  font-size: 32px;
  margin-top: 16px;
  text-align: right;
}
.job-title {
  display: inline-block;
  font-family: "Barlow Condensed", sans-serif;
  position: relative;
  top: -18px;
  left: 50px;
  text-align: center;
}
.job-title :nth-child(2) {
  margin-left: 10px;
}
.service-card {
  width: 50%;
  min-width: 270px;
  height: auto;
}

.specialty-three {
  display: flex;
  flex-direction: row;
}
.special-head {
  background: black;
}
.special-head h2 {
  color: #d9af53;
  font-family: "Abril Fatface", cursive;
}
ul {
  text-align: left;
  font-family: "Roboto", sans-serif;
  color: var(--bs-nav-link-color);
}
h3 {
  font-family: "Roboto", sans-serif;
  color: var(--bs-nav-link-color);
}
.innercard {
  margin: auto;
}
.right-info {
  width: 50%;
  min-width: 540px;
  height: auto;
}

@media (max-width: 1000px) {
  q {
    font-size: 22px;
  }
}
@media (max-width: 600px) {
  q {
    font-size: 20px;
  }
  .right-info {
    width: 100%;
    min-width: 300px;
  }
  .service-card {
    width: 100%;
  }
}
@media (max-width: 470px) {
  q {
    font-size: 18px;
  }
}
</style>
